import React, {useEffect, useState} from "react";
import * as CS from "./CryptoAnimation.styled";
import {PictureTemplate} from "../common";
import i18n from "../../../i18n";
import promisifyLoadImage from "../../../photolab/helpers/promisify-load-image";

const defaultAssetsPath = "/assets/images/crypto-banner/crypto-animation";

const iconsList1 = [
  "top-right",
  "top-left-1",
  "bottom-right",
];

const iconsList2 = [
  "top-right-1",
  "top-right-2",
  "top-left",
];

const labelsList = {
  "top-left": "Ethan",
  "top-right": "Sophia",
  "bottom-left": "Emily",
  "bottom-right": "Lucas",
  "center": "Olivia"
};

const repeatTracksSlider = [0, 1];

const carouselItems = [];

let i = 1;

do {
  carouselItems.push(i);
  i < 4 ? i++ : i = 1;
} while (carouselItems.length < 4);

const images = carouselItems.flatMap((key) => [
  `${defaultAssetsPath}/img_1_${key}.png`,
  `${defaultAssetsPath}/img_1_${key}.webp`,
]);

Promise.all(images.map((src) => promisifyLoadImage(src)));

export const CryptoAnimation_v1 = ({cbMount, cbUnmount, cbClick, ...props}) => {
  let isClicked = false;

  const [isPageVisible, setIsPageVisible] = useState(document.visibilityState === "visible");

  useEffect(() => {
    cbMount(props);

    const handleVisibilityChange = () => {
      setIsPageVisible(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (!isClicked) {
        cbUnmount(props);
      }
    }
  }, []);

  const handleClick = () => {
    cbClick(props);
  }

  return <CS.Banner onClick={handleClick} className={isPageVisible ? "animate" : ""}>
    <CS.Header>
      <CS.Block gap={5}>
        <CS.Text
          dangerouslySetInnerHTML={{__html: i18n.t("crypto_animation_banner__text_1")}}
        />
        <CS.Text
          className={isPageVisible ? "animate" : ""}
          background={1}
          dangerouslySetInnerHTML={{__html: i18n.t("crypto_animation_banner__text_2")}}
        />
      </CS.Block>
      <CS.Block gap={8}>
        <CS.Button dangerouslySetInnerHTML={{__html: i18n.t("crypto_animation_banner__button")}} />
        <CS.Caption dangerouslySetInnerHTML={{__html: i18n.t("crypto_animation_banner__sponsored", {app_name: "NewProfilePic"})}} />
      </CS.Block>
    </CS.Header>

    <CS.FirstScreen>
      <CS.Content large>
        <CS.BackImage>
          <PictureTemplate
            className={isPageVisible ? "animate" : ""}
            scaleSlowAnimation
            element={CS.ItemBackImage}
            png={`${defaultAssetsPath}/back.png`}
            webp={`${defaultAssetsPath}/back.webp`}
          />
        </CS.BackImage>

        <CS.FrontImage>
          <PictureTemplate
            className={isPageVisible ? "animate" : ""}
            center
            scaleFastAnimation
            element={CS.ItemFrontImage}
            png={`${defaultAssetsPath}/front.png`}
            webp={`${defaultAssetsPath}/front.webp`}
          />
        </CS.FrontImage>

        {Object.entries(labelsList).map(([key, value]) => (
          <CS.Label key={key} position={key}>{value}</CS.Label>
        ))}
      </CS.Content>
      {iconsList1.map((position) => (
        <PictureTemplate
          position={position}
          circle
          element={CS.BackgroundCoin}
          png={`${defaultAssetsPath}/icons/${position}.png`}
          key={position}
        />
      ))}
    </CS.FirstScreen>

    <CS.SecondScreen>
      <CS.Content>
        <CS.Track>
          {repeatTracksSlider.map((key, index) => (
            <CS.TrackItems key={index} className={isPageVisible ? "animate" : ""}>
              {carouselItems.map((key, index) => (
                <CS.TrackItem key={index}>
                  <PictureTemplate
                    element={CS.ItemSlideImage}
                    png={`${defaultAssetsPath}/img_1_${key}.png`}
                    webp={`${defaultAssetsPath}/img_1_${key}.webp`}
                  />
                </CS.TrackItem>
              ))}
            </CS.TrackItems>
          ))}
        </CS.Track>

        <CS.Track>
          {repeatTracksSlider.map((key, index) => (
            <CS.TrackItems key={index} reverse className={isPageVisible ? "animate" : ""}>
              {carouselItems.map((key, index) => (
                <CS.TrackItem key={index}>
                  <PictureTemplate
                    element={CS.ItemSlideImage}
                    png={`${defaultAssetsPath}/img_2_${key}.png`}
                    webp={`${defaultAssetsPath}/img_2_${key}.webp`}
                  />
                </CS.TrackItem>
              ))}
            </CS.TrackItems>
          ))}
        </CS.Track>
      </CS.Content>

      {iconsList2.map((position) => (
        <PictureTemplate
          position={position}
          element={CS.BackgroundCoin}
          png={`${defaultAssetsPath}/icons/${position}.png`}
          key={position}
        />
      ))}
    </CS.SecondScreen>
  </CS.Banner>
}
