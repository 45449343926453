class LoadImageError extends Error {
  name = "LoadImageError";
  constructor(url) {
    super("Failed to load image");
    this.url = url;
  }
}

export default function promisifyLoadImage(url, crossOrigin) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    crossOrigin && (image.crossOrigin = "anonymous");
    image.onload = () => resolve(image);
    image.onerror = () => reject(new LoadImageError(url));
    image.src = url;
  });
}
