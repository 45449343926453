export function debounce(name, delay, func) {
  window.appDebounceTimers = window.appDebounceTimers || {};
  clearTimeout(window.appDebounceTimers[name]);
  return window.appDebounceTimers[name] = setTimeout(func, delay);
}

export function throttle(delay, func) {
  let isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(function() {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, delay);
  }

  return wrapper;
}

export function assetUrl(path) {
  const suffix = process.env.REACT_APP_ASSETS_VERSION
    ? ("?" + process.env.REACT_APP_ASSETS_VERSION)
    : "";

  return `${window.appConfig.paths.assets}/${path}${suffix}`;
}

export function pwAssetUrl(path) {
  const suffix = process.env.REACT_APP_ASSETS_VERSION
    ? ("?" + process.env.REACT_APP_ASSETS_VERSION)
    : "";

  return `${window.appConfig.paths.pwAssets}/${path}${suffix}`;
}

export function processingAssetUrl(path) {
  return `${window.appConfig.paths.processingsAssets}/${path}`;
}

export function when(key, map) {
  return map.hasOwnProperty(key) ? map[key] : undefined;
}
