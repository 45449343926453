import React from "react";

export function PictureTemplate({element, png, webp, ...props}) {
  const Element = element;

  return <Element {...props}>
		<source srcSet={webp} type="image/webp" />
		<img src={png} alt="" />
  </Element>
}
