import styled from "styled-components";

export const Container = styled.main`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;

  .resubscribe & {
    width: 124px;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;

    @media all and (max-height: 690px) and (orientation: portrait) {
      width: 108px;
      height: 108px;
      margin-bottom: 8px;
    }
  }

  ${props => props.showCryptoBanner && `
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    will-change: transform;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
  `};
`;

export const LoaderContainer = styled.div`
  ${props => props.showCryptoBanner && `
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 3 1 0%;
    margin-bottom: 16px;
    flex-direction: column;
  `};
`;

export const TextContainer = styled.div`
  position: absolute;
  top: calc(40% + 220px);
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 80%;
  text-align: center;
  margin: 0 auto;

  .resubscribe & {
    display: none;
  }

  ${props => props.showCryptoBanner && `
    position: static;
    transform: none;
    width: 100%;
    text-align: center;
    padding: 0 15px;
    box-sizing: border-box;
  `}
`

export const TextTip = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #01e777;
  margin-bottom: 16px;

  ${props => props.showCryptoBanner && `
    margin-bottom: 6px;

    @media all and (max-height: 760px) and (orientation: portrait) {
      line-height: 1.25;
    }

    @media all and (max-height: 760px) and (orientation: portrait) {
      font-size: 14px;
    }
  `}
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #fff;

  ${props => props.showCryptoBanner && `
    @media all and (max-height: 760px) and (orientation: portrait) {
      line-height: 1.25;
    }

    @media all and (max-height: 760px) and (orientation: portrait) {
      font-size: 14px;
    }
  `}
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  .resubscribe & {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    display: flex;
    justify-content: center;
  }

  ${props => props.showCryptoBanner && `
    position: relative;
    transform: none;
    top: 0;
    left: 0;
    margin-bottom: 16px;
    align-self: center;
  `};
`;

const Spinner = styled.div`
  display: block;
  position: relative;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  border: solid 1px rgba(255, 255, 255, 0.15);

  &::before  {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 1px solid transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .resubscribe & {
    width: 100px;
    height: 100px;
    border-width: 2px;

    &::before {
      border-width: 2px;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
    }

    @media all and (max-height: 690px) and (orientation: portrait) {
      width: 86px;
      height: 86px;
    }
  }
`

export const SpinnerBig = styled(Spinner)`
  &::before  {
    border: 1px solid #01e777;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
  }

  @keyframes spin-reverse {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(-360deg);
    }
  }

  ${props => props.showCryptoBanner && `
    @media all and (max-height: 850px) and (orientation: portrait) {
      width: 180px;
      height: 180px;
    }

    @media all and (max-height: 760px) and (orientation: portrait) {
      width: 150px;
      height: 150px;
    }

    @media all and (max-height: 530px) and (orientation: portrait) {
      width: 130px;
      height: 130px;
    }
  `};
`

export const SpinnerSmall = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;

  &::before {
    border: 1px solid #01e777;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: spin 3s linear infinite;
  }

  .resubscribe & {
    width: 120px;
    height: 120px;
    border-width: 2px;

    &::before {
      border-width: 2px;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
    }

    @media all and (max-height: 690px) and (orientation: portrait) {
      width: 104px;
      height: 104px;
    }
  }

  ${props => props.showCryptoBanner && `
    @media all and (max-height: 850px) and (orientation: portrait) {
      width: 140px;
      height: 140px;
    }

    @media all and (max-height: 760px) and (orientation: portrait) {
      width: 110px;
      height: 110px;
    }

    @media all and (max-height: 530px) and (orientation: portrait) {
      width: 90px;
      height: 90px;
    }
  `};

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`

export const SpinnerSvgContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
  }

  .resubscribe & {
    width: 60px;
    height: 60px;

    @media all and (max-height: 690px) and (orientation: portrait) {
      width: 52px;
      height: 52px;
    }
  }

  ${props => props.showCryptoBanner && `
    @media all and (max-height: 850px) and (orientation: portrait) {
      width: 80px;
      height: 80px;
    }

    @media all and (max-height: 760px) and (orientation: portrait) {
      width: 50px;
      height: 50px;
    }
  `};
`

export const AvatarImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  animation-name: fadein;
  animation-duration: 320ms;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  pointer-events: none;

  .resubscribe & {
    width: 60px;
    height: 60px;

    @media all and (max-height: 690px) and (orientation: portrait) {
      width: 52px;
      height: 52px;
    }
  }

  ${props => props.showCryptoBanner && `
    @media all and (max-height: 850px) and (orientation: portrait) {
      width: 80px;
      height: 80px;
    }

    @media all and (max-height: 760px) and (orientation: portrait) {
      width: 50px;
      height: 50px;
    }
  `};
`

export const CryptoBanner = styled.picture`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: end;
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    max-width: 420px;
    object-fit: contain;
    object-position: bottom;

    @media all and (max-height: 620px) and (orientation: portrait) {
      max-width: 280px;
    }

    @media all and (max-height: 570px) and (orientation: portrait) {
      max-width: 240px;
    }

    @media all and (max-height: 490px) and (orientation: portrait) {
      max-width: 200px;
    }
  }
`
