import React from "react";
import i18n from "../../i18n";
import { AvatarImage, Container, LoaderContainer, SpinnerBig, SpinnerContainer, SpinnerSmall, SpinnerSvgContainer, Text, TextContainer, TextTip } from "./ProcessingLoader.style";
import SvgProcessingIcon from "../../ui/svg/ProcessingIcon";
import AppContext from "../../contexts/AppContext";
import {getBanner} from "../ProcessingLoadingMediator/ProcessingsLoadingMediator.helper";

const LOADING_TEXT_INTERVAL = 3000;

export default class ProcessingLoader extends React.Component {

  constructor(props) {
    super(props);

    this.texts = [
      "processing_text_1",
      "processing_text_2",
      "processing_text_3",
      "processing_text_4",
      "processing_text_5",
    ];

    this.state = {
      imageIsLoaded: false,
      //textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
      textIndex: 0,
      isShowCryptoBanner: undefined
    };

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {
    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        if (this.props.image) {
          this.image.src = this.props.image;
        }
      });
    }

    if (prevProps.isHidden !== this.props.isHidden) {
      if (this.props.isHidden) {
        clearInterval(this.updateTextIndexTimer);
        this.updateTextIndex();
      } else {
        this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
        const banner = getBanner(this.context.installedApps);

        this.setState(banner);
      }
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  render() {
    const BannerView = this.state.element ?? null;

    return <Container hidden={this.state.isShowCryptoBanner === undefined || this.props.isHidden} showCryptoBanner={this.state.isShowCryptoBanner}>
      <LoaderContainer showCryptoBanner={this.state.isShowCryptoBanner}>
        <SpinnerContainer showCryptoBanner={this.state.isShowCryptoBanner}>
          <SpinnerBig showCryptoBanner={this.state.isShowCryptoBanner}>
            <SpinnerSmall showCryptoBanner={this.state.isShowCryptoBanner} />
          </SpinnerBig>

          {!this.state.imageIsLoaded && <SpinnerSvgContainer showCryptoBanner={this.state.isShowCryptoBanner}>
            <SvgProcessingIcon />
          </SpinnerSvgContainer>}

          {this.state.imageIsLoaded && <AvatarImage
            src={this.props.image}
            showCryptoBanner={this.state.isShowCryptoBanner}
            alt="" />}
        </SpinnerContainer>
        <TextContainer hidden={this.props.hideText} showCryptoBanner={this.state.isShowCryptoBanner}>
          <TextTip showCryptoBanner={this.state.isShowCryptoBanner} dangerouslySetInnerHTML={{__html: i18n.t("processing_tip", {item: this.state.textIndex + 1, total: this.texts.length})}} />
          <Text showCryptoBanner={this.state.isShowCryptoBanner} dangerouslySetInnerHTML={{__html: i18n.t(this.texts[this.state.textIndex]) || ""}} />
        </TextContainer>
      </LoaderContainer>

      {BannerView && <BannerView
        {...this.props}
        {...this.state.bannerProps}
        bannerId={this.state.bannerId}
      />}
    </Container>;
  }
}

ProcessingLoader.contextType = AppContext;
