import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body, div, span, 
  h1, h2, h3, h4, h5, h6, p,
  a, img, ol, ul, li,
  form, label,
  article, aside, footer, header, 
  nav, section {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* make sure to set some focus styles for accessibility */
  :focus {
    outline: 0;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  input[type=search]::-webkit-search-cancel-button,
  input[type=search]::-webkit-search-decoration,
  input[type=search]::-webkit-search-results-button,
  input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
  }

  html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
  }

  a:focus {
    outline: thin dotted;
  }

  /**
  * Improve readability when focused and also mouse hovered in all browsers.
  */

  a:active,
  a:hover {
    outline: 0;
  }

  img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
  }

  button,
  input,
  select,
  textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
  }

  button,
  input {
    line-height: normal;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"], /* 1 */
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
  }

  input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /**
  * Remove inner padding and border in Firefox 3+.
  */

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
  }

  html,
  button,
  input,
  select,
  textarea {
    color: #222;
  }

  img {
    vertical-align: middle;
  }

  textarea {
    resize: vertical;
  }

  @font-face {
    font-family: 'FuzzyBubbles';
    font-weight: 400;
    src: url('/assets/fonts/FuzzyBubbles-Regular.woff2') format('woff2');
    font-display: block;
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('/assets/fonts/PoppinsRegular.woff2') format('woff2'),
      url('/assets/fonts/PoppinsRegular.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('/assets/fonts/PoppinsBold.woff2') format('woff2'),
      url('/assets/fonts/PoppinsBold.woff') format('woff');
  }

  @font-face {
    font-family: "RedHatDisplay";
    src: url("/assets/fonts/RedHatDisplay/RedHatDisplay-Black.woff2") format("woff2"),
        url("/assets/fonts/RedHatDisplay/RedHatDisplay-Black.woff") format("woff");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
  }

  @font-face {
    font-family: "RedHatDisplay";
    src: url("/assets/fonts/RedHatDisplay/RedHatDisplay-BlackItalic.woff2") format("woff2"),
        url("/assets/fonts/RedHatDisplay/RedHatDisplay-BlackItalic.woff") format("woff");
    font-style: italic;
    font-weight: 900;
    font-display: swap;
  }

  @font-face {
    font-family: "RedHatDisplay";
    src: url("/assets/fonts/RedHatDisplay/RedHatDisplay-Bold.woff2") format("woff2"),
        url("/assets/fonts/RedHatDisplay/RedHatDisplay-Bold.woff") format("woff");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "RedHatDisplay";
    src: url("/assets/fonts/RedHatDisplay/RedHatDisplay-Regular.woff2") format("woff2"),
        url("/assets/fonts/RedHatDisplay/RedHatDisplay-Regular.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
  }

  [hidden] {
    display: none !important;
  }

  .file-field-hidden {
    display: none;
  }

  body {
    font-family: "DM", sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #000;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .app-lang--ru {
    font-family: "Noto Sans", sans-serif;
  }
  .app-lang--ja {
    font-family: "Noto Sans JP", sans-serif;
  }
  .app-lang--ko {
    font-family: "Noto Sans KR", sans-serif;
  }
  .app-lang--zh {
    font-family: "Noto Sans SC", sans-serif;
  }

  .container {
    max-width: 1110px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
  }

  p {
    line-height: normal;
  }

  button {
    cursor: pointer;
    position: relative;
    padding: 0;
    input {
      opacity: 0;
      height: 0 !important;
      position: absolute;
      padding: 0 !important;
      margin: 0 !important;
    }
    &[disabled] {
      color: #999;
      background-color: #f3f3f3;
    }
  }

  a {
    text-decoration: none;
  }

  input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  button {
    border: none;
  }

  .-show-popup {
    overflow: hidden;
  }

  .hair-modal {
    padding: 40px 24px 24px;

    h1 {
      font-size: 20px;
      margin-bottom: 24px;
    }

    .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      button {
        height: 68px;
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: none;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        text-transform: inherit;
        margin: 0;
        padding: 0;
        margin-bottom: 16px;

        img {
          width: 48px;
          height: 48px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .hair-style-modal {
    h1 {
      margin-bottom: 24px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .hair-style__container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 40px;
      }

      .hair-color__container {
        position: relative;
        margin-bottom: 30px;
      }

      button {
        &.color-btn {
          width: calc(100% / 7);
          padding: 2px;
          background: none;
          border: 2px solid transparent;
          border-radius: 8px;
          transition: border-color 240ms;

          div {
            width: 100%;
            height: 24px;
            border-radius: 4px;
            position: relative;
            overflow: hidden;
          }

          p {
            display: none;
            position: absolute;
            top: calc(100% + 4px);
            left: 50%;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            white-space: nowrap;
            transform: translateX(-50%);
          }

          &.active {
            border-color: #01e777;

            p {
              display: block;
            }
          }
        }

        &.style-btn {
          width: calc((100% / 3) - (16px / 3));
          background: none;
          padding: 2px;
          border: 2px solid transparent;
          border-radius: 14px;
          transition: border-color 240ms;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }

          &.active {
            border-color: #01e777;
          }
        }
      }
    }

    .error-message {
      position: absolute;
      top: -16px;
      left: 5px;
      font-size: 12px;
      line-height: 16px;
      color: #f70000;
    }
  }
`;

export default GlobalStyle;
