import styled from "styled-components";
import {flexMixin} from "../common.styled";

export const Content = styled.div`
  width: 100%;
  min-height: 206px;
  max-height: 48vh;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 24vh;
    z-index: 2;
  }

  &::before {
    bottom: 0;
    background: linear-gradient(0, rgba(9, 9, 11, 1) 0%, rgba(9, 9, 11, 0) 100%);
  }

  &::after {
    top: -6vh;
    background: linear-gradient(0deg,rgba(31, 13, 63, 0) 0,  #2A0F59 80%, transparent 100%);
  }

  ${props => props.large && `
    max-height: 52vh;

     &::after {
      content: none;
     }
  `}
`;

export const Track = styled.div`
  height: calc(50% - 3px);
  ${flexMixin("flex-start", "center")}
`;

export const TrackItems = styled.div`
  height: 100%;
  ${flexMixin("flex-start", "center")}
  flex-grow: 1;

  @keyframes carouselAnimation {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  ${props => props.reverse && `
    @keyframes carouselReverseAnimation {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  `}

  &.animate {
    animation: carouselAnimation 16s linear 1s infinite;

    ${props => props.reverse && `
      animation: carouselReverseAnimation 8s linear 1s infinite;
    `}
  }
`;

export const TrackItem = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  will-change: transform;
  margin-left: 5px;
  height: 100%;
  aspect-ratio: 1.15 / 1;

`;

export const ItemBackImage = styled.picture`
  position: absolute;
  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    ${props => props.scaleSlowAnimation && `
      @keyframes scaleSlowAnimation {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(1.15);
        }
      }
    `}
  }

  &.animate {
    img {
       ${props => props.scaleSlowAnimation && `
        animation: scaleSlowAnimation 6s linear infinite;
       `}
    }
  }
`;

export const ItemFrontImage = styled.picture`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    ${props => props.center && `
      display: flex;
      width: auto;
      margin: 0 auto;
    `}

    ${props => props.scaleFastAnimation && `
      @keyframes scaleFastAnimation {
        0% {
          transform: scale(0.9);
        }

        100% {
          transform: scale(1.5);
        }
      }
    `}
  }

  &.animate {
    img {
       ${props => props.scaleFastAnimation && `
        animation: scaleFastAnimation 6s linear infinite;
      `}
    }
  }
`;

export const ItemSlideImage = styled.picture`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const Block = styled.div`
  ${flexMixin("center", "center", "column")}
  gap: ${props => props.gap}px;  
`;

export const Text = styled.p`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  position: relative;
  color: #fff !important;
  z-index: 5;
  margin-bottom: ${props => props.mb}px;
  text-align: center;
  padding: 0 14px;

  @keyframes swingAnimation {
    0% {
      transform: rotate(2deg);
      transform-origin: center center;
    }

    50% {
      transform: rotate(-2deg);
      transform-origin: center center;
    }

    100% {
      transform: rotate(2deg);
      transform-origin: center center;
    }
  }

  ${props => props.background && `
    &::before {
      content: "";
      position: absolute;
      top: -25%;
      left: 0;
      width: 100%;
      height: 150%;
      background: #955CF6;
      z-index: -1;
      transform: rotate(0deg);
      transform-origin: center center;
      border-radius: 60px;
    }
  `}

  &.animate {
    ${props => props.background && `
      &::before {
       animation: swingAnimation 1s linear infinite;
      }
    `}
  }
`;

export const Button = styled.div`
  ${flexMixin("center", "center")}
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  height: 40px;
  padding: 0 24px;
  border-radius: 50px;
  background: linear-gradient(to right, #FFC229, #F43F67);
  color: #fff;
  z-index: 5;
  text-transform: uppercase;
`;

export const Caption = styled.p`
  font-size: 9px;
  font-style: normal;
  color: #fff;
  z-index: 5;
`;

export const Header = styled.div`
  ${flexMixin("space-between", "center", "column")}
  gap: 5px;
  height: 100%;
`;

// background
export const BackgroundImage = styled.picture`
  position: absolute;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: bottom;
  }
`;

export const BackgroundCoin = styled(BackgroundImage)`
  @keyframes iconAnimation {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-5px, -14px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  animation: iconAnimation 2s linear infinite;

  z-index: 3;
  height: auto;

  img {
    object-position: center;

    filter: none !important;
    mix-blend-mode: normal !important;
    opacity: 1 !important;

    @media (prefers-color-scheme: dark) {
      filter: none !important;
    }
  }

  ${props => props.circle && `
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .1);
    ${flexMixin("center", "center")}

    img {
      width: 22px;
    }
  `}

  ${props => props.position === "top-left" && `
    width: 6%;
    left: 6%;
    top: 18%;
  `}

  ${props => props.position === "top-left-1" && `
    left: -1%;
    top: 35%;
  `}

  ${props => props.position === "top-right" && `
    top: 8%;
    right: 5%;
  `}

  ${props => props.position === "top-right-1" && `
    width: 4%;
    top: 6%;
    right: 8%;
    transform: rotate(45deg);
  `}

  ${props => props.position === "top-right-2" && `
    width: 7%;
    top: 18%;
    right: 16%;
    animation-delay: 1s;
  `}

  ${props => props.position === "bottom-right" && `
    bottom: 4%;
    right: 10%;
    animation-delay: -1s;
  `}
`;

export const Label = styled.div`
  background-color: #fff;
  color: #09090B;
  font-size: 13px;
  border-radius: 20px;
  ${flexMixin("center", "center")}
  position: absolute;
  z-index: 4;

  ${props => props.position === "top-left" && `
    font-size: 8px;
    height: 18px;
   top: 8%;
    left: 7%;
    transform: rotate(-4deg);
    padding: 0 8px;
  `}

  ${props => props.position === "top-right" && `
    font-size: 8px;
    height: 18px;
    top: 12%;
    right: 4%;
    transform: rotate(4deg);
    padding: 0 8px;
  `}

  ${props => props.position === "bottom-left" && `
    height: 26px;
    bottom: 12%;
    left: 4%;
    transform: rotate(-4deg);
    padding: 0 11px;
  `}

  ${props => props.position === "bottom-right" && `
    height: 26px;
    bottom: 20%;
    right: 4%;
    transform: rotate(3deg);
    padding: 0 11px;
  `}

  ${props => props.position === "center" && `
    height: 26px;
    top: 48%;
    left: 28%;
    padding: 0 11px;

    &::before {
      content: "Admin";
      ${flexMixin("center", "center")}
      color: #fff;
      font-size: 8px;
      position: absolute;
      top: -11px;
      left: 0px;
      height: 16px;
      padding: 0 5px;
      border-radius: 10px;
      background-color: #09090B;
      transform: rotate(-4deg);
    }
  `}
`

export const FirstScreen = styled.div`
  opacity: 1;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`

export const SecondScreen = styled.div`
  opacity: 0;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

export const BackImage = styled.div``

export const FrontImage = styled.div``

export const Banner = styled.div`
  ${flexMixin("space-between", "center", "column")}
  font-family: "RedHatDisplay", sans-serif;
  gap: 10px;
  position: relative;
  flex: 4;
  max-height: 65vh;
  height: 100%;
  width: 100%;

  background: linear-gradient(180deg, #2A0F59 0%,  #09090B 100%);
  border-radius: 40px 40px 0 0;
  overflow: hidden;
  padding: 21px 0 16px;

  @media (max-height: 600px) and (orientation: portrait) {
    flex: 3;
    max-height: 50vh;
  }

  &.animate {
    ${FirstScreen} {
      animation: fadeOut 1s forwards 3s;
    }

    ${SecondScreen} {
      animation: fadeIn 1s forwards 3s;
    }
  }
`;
