// общие конфигураторы для часто используемых стилей
export function flexMixin(justify, align, direction = "row") {
  return `
    display: flex;
    justify-content: ${justify};
    align-items: ${align};
    flex-direction: ${direction};
  `;
}

export function fontMixin(size, height, weight = "400", family = "Helvetica") {
  return `
    font-family: "${family}", sans-serif;
    font-size: ${size};
    line-height: ${height ? `${height}px` : 1.3};
    font-weight: ${weight};
  `;
}