/* eslint-disable no-unused-vars */

import axios from "axios";
import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";
import promiseRetry from "promise-retry";

const xmlParser = new DOMParser();
const httpClient = axios.create({
  baseURL: window.appConfig.photolab.path,
});

const tasksData = {};
const listeners = [];

let defaultAddTaskEndpointId = 0;
const addTaskEndpoints = [
  {
    url: window.appConfig.photolab.addTaskEndpoint1,
    retries: 1,
    retriesTimeoutMin: 1000,
    retriesTimeoutMax: 1000,
  },
  {
    url: window.appConfig.photolab.addTaskEndpoint2,
    retries: 3,
    retriesTimeoutMin: 1000,
    retriesTimeoutMax: 3000,
  },
  {
    url: window.appConfig.photolab.addTaskEndpoint3,
    retries: 3,
    retriesTimeoutMin: 3000,
    retriesTimeoutMax: 3000,
  },
];

export class PhotolabResponseError extends Error {

  name = "PhotolabResponseError";

  constructor(code, message, requestId = undefined) {
    super(message);
    this.code = code;
    this.requestId = requestId;
  }
}

async function getResultTask(requestId, isRetry) {
  function requestFunc(retry) {
    return httpClient.post("/getresult?request_id=" + requestId + "&r=" + Math.random())
      .catch(retry);
  }

  try {
    const response = await promiseRetry(requestFunc, {
      retries: 5,
      minTimeout: 1000,
      maxTimeout: 1000,
    });

    return parseTaskResult(response.data, requestId);
  } catch (err) {
    // bad-request-id fix
    if (err instanceof PhotolabResponseError && err.code === 612) {
      if (isRetry) {
        throw err;
      }

      await new Promise((resolve) => setTimeout(resolve, 3000));
      return getResultTask(requestId, true);
    }

    throw err;
  }
}

export function photolabSimpleTask(templateId, imageObjectOrUrl, timeout = 1000, interval = 500) {
  const taskConfig = new PhotolabTaskBuilder();
  taskConfig.addMethod(new PhotolabTaskCollageMethod({template_name: templateId}));
  taskConfig.addImage(new PhotolabTaskImageUrl(imageObjectOrUrl));
  taskConfig.setLanguage(window.clientConfig.lang);

  return photolabAddTask(taskConfig.buildToJs())
    .then((taskResult) => {
      if (taskResult.errorCode !== 0) {
        throw new PhotolabResponseError(
          taskResult.errorCode,
          taskResult.description,
          taskResult.requestId,
        );
      }

      return photolabWaitTask(taskResult.requestId, timeout, interval)
    });
}

export function photolabTask(taskConfig, optionsArg) {
  const options = Object.assign({
    timeout: 1000,
    interval: 1000,
  }, optionsArg);

  return photolabAddTask(taskConfig).then((taskResult) => {
    if (taskResult.errorCode !== 0) {
      throw new PhotolabResponseError(
        taskResult.errorCode,
        taskResult.description,
        taskResult.requestId,
      );
    }

    return photolabWaitTask(taskResult.requestId, options.timeout, options.interval);
  });
}

export function photolabAddTask(taskJsConfig, optionsArg) {
  const options = {
    endpointId: defaultAddTaskEndpointId,
    ...optionsArg,
  };

  const endpoint = addTaskEndpoints[options.endpointId];
  const startedAt = Date.now();

  const requestFunc = (retry) => {
    return httpClient.post(endpoint.url, {
      // i_am_debugger: true,
      app_id: window.appConfig.photolab.appId,
      client_token: window.clientConfig.token,
      client_build: window.appConfig.build.version,
      project_name: window.appConfig.project.name,
      task: taskJsConfig,
    }).then((res) => {
      // console.log(res.data.data.request.xml);
      tasksData[res.data.task.requestId] = {
        requestId: res.data.task.requestId,
        config: taskJsConfig,
        templateName: res.data.extra.tid,
        startedAt,
      };

      fireEvent("onTaskAdded", tasksData[res.data.task.requestId]);

      return res.data.task;
    }).catch(retry);
  };

  return promiseRetry(requestFunc, {
    retries: endpoint.retries,
    minTimeout: endpoint.retriesTimeoutMin,
    maxTimeout: endpoint.retriesTimeoutMax,
  }).then((res) => {
    if (res.status === "Error") {
      throw new PhotolabResponseError(res.errorCode, res.description, res.requestId);
    }

    return res;
  }).catch((err) => {
    if (err instanceof PhotolabResponseError) {
      throw err;
    }

    defaultAddTaskEndpointId = 1;
    const nextEndpointId = options.endpointId + 1;
    if (nextEndpointId >= addTaskEndpoints.length) {
      throw err;
    } else {
      return photolabAddTask(taskJsConfig, {
        ...optionsArg,
        endpointId: nextEndpointId,
      });
    }
  });
}

export function photolabWaitTask(requestId, timeout = 0, interval = 1000) {
  function getResultFunc(resolve, reject) {
    getResultTask(requestId)
      .then((taskResult) => {
        if (taskResult.status === "OK") {
          if (taskResult.resultUrl) {
            taskResult.resultUrl = taskResult.resultUrl.replace("http://", "https://")
          }

          tasksData[requestId] = tasksData[requestId] || {};
          tasksData[requestId].finishedAt = Date.now();
          tasksData[requestId].result = taskResult;

          resolve(taskResult);

          fireEvent("onTaskResult", tasksData[requestId]);
        } else {
          photolabWaitTask(requestId, interval, interval).then(resolve).catch(reject);
        }
      })
      .catch((err) => {
        tasksData[requestId] = tasksData[requestId] || {};
        tasksData[requestId].finishedAt = Date.now();
        tasksData[requestId].error = err;

        reject(err);

        fireEvent("onTaskResult", tasksData[requestId]);
      });
  }

  return new Promise((resolve, reject) => {
    if (timeout <= 0) {
      getResultFunc(resolve, reject);
    } else {
      setTimeout(() => getResultFunc(resolve, reject), timeout);
    }
  });
}

function parseTaskResult(xmlString, requestIdArg) {
  const xmldoc = xmlParser.parseFromString(xmlString, "application/xml");

  if (xmldoc.documentElement.nodeName === "parsererror") {
    throw new PhotolabResponseError("parse", "Invalid XML: " + xmlString);
  }

  if (xmldoc.documentElement.querySelector("err_code") !== null) {
    const requestIdNode = xmldoc.documentElement.querySelector("request_id");

    throw new PhotolabResponseError(
      parseInt(xmldoc.documentElement.querySelector("err_code").textContent),
      xmldoc.documentElement.querySelector("description").textContent,
      requestIdNode ? requestIdNode.textContent : requestIdArg,
    );
  }

  const status = xmldoc.documentElement.querySelector("status").textContent;
  const response = {
    requestId: xmldoc.documentElement.querySelector("request_id").textContent,
    status: status,
  };

  if (status === "OK") {
    const resultUrlNode = xmldoc.documentElement.querySelector("result_url");
    if (resultUrlNode) {
      response.resultUrl = resultUrlNode.textContent;
    }

    const embeddingNode = xmldoc.documentElement.querySelector("embedding");
    if (embeddingNode) {
      response.embedding = embeddingNode.textContent;
    }

    const keypointsNode = xmldoc.documentElement.querySelector("keypoints");
    if (keypointsNode) {
      response.keypoints = keypointsNode.textContent;
    }

    const resultAnswersNode = xmldoc.documentElement.querySelector("answers");
    if (resultAnswersNode) {
      response.answers = [];
      resultAnswersNode.childNodes.forEach((answerNode) => {
        response.answers.push(answerNode.textContent);
      })
    }

    response.duration = xmldoc.documentElement.querySelector("duration").textContent;
    response.totalDuration = xmldoc.documentElement.querySelector("total_duration").textContent;

    const genderNode = xmldoc.documentElement.querySelector("gender");
    if (genderNode) {
      response.gender = {
        value: genderNode.querySelector("value").textContent,
        probability: parseFloat(genderNode.querySelector("probability").textContent),
      };
    }

    const resultsNode = xmldoc.documentElement.querySelector("results");
    if (resultsNode) {
      response.results = [];

      resultsNode.childNodes.forEach((resultNode) => {
        response.results.push({
          templateId: parseInt(resultNode.getAttribute("template_name")),
          resultUrl: resultNode.textContent,
        });
      });
    }

    const humansNode = xmldoc.documentElement.querySelector("humans");
    if (humansNode) {
      response.humans = [];
      response.originalSize = {width: 0, height: 0};

      humansNode.childNodes.forEach((humansChildNode) => {
        if (humansChildNode.nodeName === "human") {
          const humanItem = {
            skeletonPoints: [],
            bbox: {
              minPoint: {x: 0, y: 0},
              maxPoint: {x: 0, y: 0},
              color: {r: 0, g: 0, b: 0, a: 0},
            },
          };

          humansChildNode.childNodes.forEach((humanChildNode) => {
            if (humanChildNode.nodeName === "skeleton_points") {
              humanChildNode.querySelectorAll("point").forEach((pointNode) => {
                const point = {x: 0, y: 0, groupName: "", id: 0};
                point.id = parseInt(pointNode.getAttribute("id"));
                point.groupName = pointNode.getAttribute("group_name");
                point.x = parseFloat(pointNode.querySelector("x").textContent);
                point.y = parseFloat(pointNode.querySelector("y").textContent);
                point.p = parseFloat(pointNode.querySelector("p").textContent);
                humanItem.skeletonPoints.push(point);
              });
            } else if (humanChildNode.nodeName === "bbox") {
              humanItem.bbox.minPoint.x = parseFloat(humanChildNode.querySelector("min_point > x").textContent);
              humanItem.bbox.minPoint.y = parseFloat(humanChildNode.querySelector("min_point > y").textContent);
              humanItem.bbox.maxPoint.x = parseFloat(humanChildNode.querySelector("max_point > x").textContent);
              humanItem.bbox.maxPoint.y = parseFloat(humanChildNode.querySelector("max_point > y").textContent);
              humanItem.bbox.color.r = parseFloat(humanChildNode.querySelector("Color > R").textContent);
              humanItem.bbox.color.g = parseFloat(humanChildNode.querySelector("Color > G").textContent);
              humanItem.bbox.color.b = parseFloat(humanChildNode.querySelector("Color > B").textContent);
              humanItem.bbox.color.a = parseFloat(humanChildNode.querySelector("Color > A").textContent);
            }
          });

          response.humans.push(humanItem);
        } else if (humansChildNode.nodeName === "original_size") {
          response.originalSize.width = parseInt(humansChildNode.querySelector("width").textContent);
          response.originalSize.height = parseInt(humansChildNode.querySelector("height").textContent);
        }
      });
    }

    const predictionsNode = xmldoc.documentElement.querySelector("predictions");
    if (predictionsNode) {
      response.predictions = {};

      predictionsNode.childNodes.forEach((node) => {
        response.predictions[node.tagName] = node.textContent;
      });
    }
  }

  return response;
}

export function addListener(listener) {
  const _listener = Object.assign({
    onTaskAdded: () => {},
    onTaskResult: () => {},
  }, listener);

  listeners.push(_listener);

  return _listener;
}

export function removeListener(listener) {
  const pos = listeners.indexOf(listener)
  if (pos >= 0) {
    listeners.splice(pos, 1);
  }
}

function fireEvent(event, ...data) {
  listeners.forEach((listener) => {
    listener[event].call(null, ...data);
  });
}
